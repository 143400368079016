import { useSelection } from "@frend-digital/centra/client";

export const useCartItemCount = () => {
	const { data } = useSelection({
		select: (data) => data.selection?.items?.length,
	});

	return data;
};

export const useCart = () => {
	return useSelection({
		select: (data) => {
			return {
				total: data.selection?.totals?.grandTotalPrice,
				totalPriceAsNumber: data.selection?.totals?.grandTotalPriceAsNumber,
				itemsTotalPriceAsNumber: data.selection?.totals?.itemsTotalPriceAsNumber,
				itemsTotalPrice: data.selection?.totals?.itemsTotalPrice,
				items: data.selection?.items,
				itemCount: data.selection?.items?.length,
				vouchers: data.selection?.discounts?.vouchers,
				shippingPrice: data.selection?.totals.shippingPrice,
				shippingPriceAsNumber: data.selection?.totals?.shippingPriceAsNumber,
			};
		},
	});
};

export const useGeoData = () => {
	return useSelection({
		select: (data) => {
			const market = data.location?.market;
			return {
				market,
				pricelist: data.location?.pricelist,
				currency: data.selection?.currency,
			};
		},
	});
};

export type CartHook = ReturnType<typeof useCart>["data"];

export type CartItem = NonNullable<NonNullable<CartHook>["items"]>[number];
