"use client";

import { CarouselItem } from "@/components/ui/Carousel";
import { useDevice } from "@/features/Device";
import type { ProductCard as ProductCardType } from "@/lib/centra/formatters";
import { useRef } from "react";
import { ProductCard } from "../ProductCard";
import { ProductCardSkeleton } from "../ProductCard/ProductCardSkeleton";
import { CarouselWrapper, type CarouselProps } from "./CarouselWrapper";
import styles from "./index.module.css";

export interface ProductCarouselProps extends Omit<CarouselProps, "children"> {
	products: ProductCardType[];
	className?: string;
}

export const ProductCarousel = ({ products, ...rest }: ProductCarouselProps) => {
	const { mobile } = useDevice();
	const trackRef = useRef<HTMLSpanElement | null>(null);

	const handleScrollProgressChange = (scrollProgress: number) => {
		if (trackRef.current) {
			trackRef.current.style.transform = `translateX(${scrollProgress * (100 * (products.length - 1))}%)`;
		}
	};

	return (
		<>
			<CarouselWrapper
				onScrollProgressChange={handleScrollProgressChange}
				pagination={products.length > 4}
				{...rest}>
				{products.map((p, index) => (
					<CarouselItem key={index}>
						<ProductCard product={p} />
					</CarouselItem>
				))}
			</CarouselWrapper>
			{products.length > (mobile ? 2 : 4) && (
				<div className={styles.scrollbar}>
					<span
						ref={trackRef}
						className={styles.track}
						style={{
							width: `${(1 / products.length) * 100}%`,
						}}
					/>
				</div>
			)}
		</>
	);
};

export const ProductSliderCarouselSkeleton = ({ size = 4 }: { size?: number }) => {
	return (
		<div style={{ display: "flex", gap: "2px" }}>
			{Array.from({ length: size }).map((_, i) => (
				<ProductCardSkeleton key={i} />
			))}
		</div>
	);
};

export const ProductCarouselSkeleton = ({ count }: { count: number }) => {
	return (
		<div data-layout={count <= 4 ? "page" : "full"}>
			<ProductCardSkeleton />
			<ProductCardSkeleton />
			<ProductCardSkeleton />
			<ProductCardSkeleton />
		</div>
	);
};
