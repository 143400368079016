"use client";

import { Carousel } from "@/components/ui/Carousel";
import { clsx } from "@frend-digital/ui";
import { type ComponentProps } from "react";
import styles from "./index.module.css";

export const CarouselWrapper = ({
	className,
	children,
	onIndexChange,
	itemsPerSlide,
	...props
}: ComponentProps<typeof Carousel>) => {
	return (
		<Carousel
			onIndexChange={onIndexChange}
			classNames={{
				root: styles.carousel,
				viewport: styles.viewport,
			}}
			className={clsx(className)}
			{...props}
			itemsPerSlide={itemsPerSlide}>
			{children}
		</Carousel>
	);
};

export type CarouselProps = ComponentProps<typeof Carousel>;
