import { Text } from "@/components/ui/Text";
import { clsx } from "@frend-digital/ui";
import { useTranslations } from "next-intl";
import Image from "next/image";
import Link from "next/link";
import { CartItemAmount, RemoveCartItem } from "./CartItemAmount";
import styles from "./index.module.css";

export const useTranslatedSize = (size: string) => {
	const t = useTranslations("size");

	switch (size.toUpperCase()) {
		case "XS":
			return t("xs");
		case "S":
			return t("s");
		case "M":
			return t("m");
		case "L":
			return t("l");
		case "XL":
			return t("xl");
		default:
			return size;
	}
};

export const CartItem = ({
	color,
	discountedPrice,
	line,
	media,
	name,
	price,
	quantity,
	size,
	className,
	hideActions,
	uri,
	sku,
}: {
	color?: string;
	discountedPrice?: string;
	line: string;
	media?: { href?: string; alt: string };
	name?: string;
	price: string;
	quantity: number;
	size?: string;
	uri?: string;
	sku?: string;
	className?: string;
	hideActions?: boolean;
}) => {
	const isOnSale = discountedPrice !== price;

	return (
		<article className={clsx(styles.root, className)}>
			<Link className={styles.link} href={`/product/${uri}`}>
				<div className={styles.imageContainer}>
					{media && media?.href && (
						<Image
							className={styles.image}
							src={media?.href}
							fill
							sizes={"50vw"}
							alt="product"
						/>
					)}
				</div>
				<div className={styles.details}>
					<dl className={styles.description}>
						<dt className="sr-only">Product Name</dt>
						<Text asChild className={styles.name} bold>
							<dd>{name}</dd>
						</Text>

						<dt className="sr-only">Variant</dt>
						<Text className={styles.variant} asChild>
							<dd>
								{color} #{sku}
							</dd>
						</Text>

						<dt className="sr-only">Size</dt>
						<Text className={styles.size} asChild>
							<dd>{useTranslatedSize(size!)}</dd>
						</Text>
					</dl>
					<div className={styles.controls}>
						<div
							className={clsx(styles.priceContainer, hideActions && styles.inCheckout)}>
							<Text block className={styles.price}>
								{price}
							</Text>
							{isOnSale && (
								<Text
									className={clsx(
										styles.priceBeforeDiscount,
										hideActions && styles.inCheckout,
									)}>
									{discountedPrice}
								</Text>
							)}
						</div>
					</div>
				</div>
			</Link>
			{!hideActions && <CartItemAmount line={line} quantity={quantity} />}
			{!hideActions && <RemoveCartItem line={line} />}
		</article>
	);
};
